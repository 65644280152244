import React, { useCallback, useMemo, useState } from "react"
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  CircularProgress,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useApolloClient, useMutation } from "@apollo/client"
import {
  RESCHEDULE_TOUR_MUTATION,
  RescheduleTourResult,
  RescheduleTourVariables,
} from "../../../../api/graphql/mutations/reschedule-tour"
import { DatePicker } from "@mui/x-date-pickers"
import moment, { Moment } from "moment"
import { GETTOURSTATS_WITH_ID_QUERY } from "../../../../api/graphql/queries/get-tour-stats-with-id"
import { useTourOverview } from "../context/tour-overview-context"
import { useSelectedTour } from "../../../../context/selected-tour-context"
import { toast } from "react-toastify"
import { useSelectedRegion } from "../../../../hooks/use-selected-region"

export interface RescheduleTourDialogProps {
  open: boolean
  onClose: () => void
  tourId: number
  currentDate: Date
}

export const RescheduleTourDialog: React.FC<RescheduleTourDialogProps> = (props: RescheduleTourDialogProps) => {
  const { open, onClose, tourId, currentDate } = props
  const [newDate, setNewDate] = useState<Moment>(moment(currentDate))
  const { t } = useTranslation()
  const client = useApolloClient()
  const { tourStatsData } = useTourOverview()
  const { selectedTour } = useSelectedTour()
  const { variables } = useSelectedRegion()

  const [rescheduleTourMutation, { loading }] = useMutation<RescheduleTourResult, RescheduleTourVariables>(
    RESCHEDULE_TOUR_MUTATION,
    {
      onCompleted: (data) => onRescheduleMutationCompleted(data.rescheduleTour),
      onError: () => toast.error(t("tour_overview.reschedule_dialog.could_not_reschedule_tour")),
    },
  )

  const onRescheduleMutationCompleted = useCallback(
    (newDate: Date) => {
      client.writeQuery({
        query: GETTOURSTATS_WITH_ID_QUERY,
        variables: {
          id: Number(selectedTour?.id),
          regionId: variables.id,
          regionType: variables.type,
        },
        data: {
          getTourStatsWithId: {
            ...tourStatsData?.getTourStatsWithId,
            tour: {
              ...tourStatsData?.getTourStatsWithId?.tour,
              date: newDate,
            },
          },
        },
      })

      toast.info(t("tour_overview.reschedule_dialog.tour_rescheduled"))

      onClose()
    },
    [tourStatsData, selectedTour, variables, client, t, onClose],
  )

  const handleConfirmClick = useCallback(() => {
    const date = moment(newDate)
    rescheduleTourMutation({
      variables: {
        tourId: String(tourId),
        newDate: date.add(date.utcOffset(), "minutes").toDate(),
      },
    })
  }, [tourId, newDate, rescheduleTourMutation])

  const handleCloseDialog = useCallback(() => {
    setNewDate(moment(currentDate))
    onClose()
  }, [currentDate, onClose])

  const newDateValid = useMemo(() => moment(newDate).isSameOrAfter(new Date(), "day"), [newDate])

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>{t("tour_overview.reschedule_dialog.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("tour_overview.reschedule_dialog.text")}</DialogContentText>
        <DatePicker
          label={t("tour_overview.reschedule_dialog.date_picker.label")}
          format={t("date_format")}
          disablePast
          value={newDate}
          onChange={(date: moment.Moment | null) => date && setNewDate(date)}
          slotProps={{
            textField: {
              helperText: newDate.isBefore(moment())
                ? t("tour_overview.reschedule_dialog.date_picker.min_date_message")
                : undefined,
              size: "small",
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} variant="contained" disabled={loading} color="inherit">
          {t("cancel")}
        </Button>
        <Button onClick={handleConfirmClick} color="primary" variant="contained" disabled={!newDateValid || loading}>
          {loading && <CircularProgress size={24} />}
          {!loading && t("tour_overview.reschedule_dialog.confirm_button_text")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
